import { login, logout, getInfo, getTIMUserSign } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    exchangeAmount: 100,
  },

  mutations: {
    SET_TOKEN: (state: any, token: any) => {
      state.token = token;
    },
    SET_NAME: (state: any, name: any) => {
      state.name = name;
    },
    SET_AVATAR: (state: any, avatar: any) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state: any, roles: any) => {
      state.roles = roles;
    },
    SET_RATE: (state: any, exchangeAmount: any) => {
      state.exchangeAmount = exchangeAmount;
    },
    SET_USERID: (state: any, id: any) => {
      state.userID = id;
    },
    SET_TIMSIGN: (state: any, sign: any) => {
      state.TIMsign = sign;
    },
    SET_TIMCODE: (state: any, txAppCode: any) => {
      state.TIMcode = txAppCode;
    },
    setIsOnSdkReady: (state: any, data: any) => {
      state.isOnSdkReady = data;
    },
    setTIM: (state: any, data: any) => {
      state.TIM = data;
    },
    SET_USERINFO: (state: any, userInfo: any) => {
      state.userInfo = userInfo;
    },
  },

  actions: {
    // 登录
    Login({ commit }: any, userInfo: any) {
      const username = userInfo.username.trim();
      return new Promise<void>((resolve, reject) => {
        login(username, userInfo.password)
          .then((response: any) => {
            const data = response.data;
            const tokenStr = data.tokenHead + data.token;
            setToken(tokenStr);
            commit('SET_TOKEN', tokenStr);

            resolve();
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }: any) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response: any) => {
            const data = response.data;
            commit('SET_NAME', data.nickname);
            commit('SET_AVATAR', data.photo);
            commit('SET_USERID', data.id);
            commit('SET_USERINFO', data);
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // 登出
    LogOut({ commit, state }: any) {
      return new Promise<void>((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }: any) {
      return new Promise<void>((resolve) => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },
    // 获取TIM签名
    GetTIM({ commit, state }: any) {
      return new Promise((resolve, reject) => {
        getTIMUserSign()
          .then((response: any) => {
            const data = response.data;
            commit('SET_TIMSIGN', data.sign);
            commit('SET_TIMCODE', data.txAppCode);
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    //TIM实例
    setTIM({ commit }: any, data: any) {
      return new Promise<void>((resolve) => {
        commit('setTIM', data);
        resolve();
      });
    },

    setIsOnSdkReady({ commit }: any, data: any) {
      return new Promise<void>((resolve) => {
        commit('setIsOnSdkReady', data);
        resolve();
      });
    },
    //存储汇率 暂时没用
    // GetSystemRate({ commit }: any) {
    //   return new Promise((resolve, reject) => {
    //     fetchRate()
    //       .then((response: any) => {
    //         commit("SET_RATE", response.data.exchangeAmount);
    //         resolve(response.data.exchangeAmount);
    //       })
    //       .catch((error: any) => {
    //         reject(error);
    //       });
    //   });
    // },
  },
};

export default user;
