import request from '@/utils/request';
import CryptoJS from 'crypto-js';
export function login(username: any, password: any) {
  password = CryptoJS.MD5(password).toString();
  return request({
    url: '/card-server/server/withdraw/login',
    method: 'post',
    data: {
      username,
      password,
    },
  });
}

export function getInfo() {
  return request({
    url: '/card-server/server/withdraw/info',
    method: 'post',
  });
}

export function logout() {
  return request({
    url: '/card-server/server/withdraw/logout',
    method: 'post',
  });
}

export function switchOpenOrder() {
  return request({
    url: '/card-server/server/withdraw/switchOpenOrder',
    method: 'post',
  });
}

export function switchCloseOrder() {
  return request({
    url: '/card-server/server/withdraw/switchCloseOrder',
    method: 'post',
  });
}

export function getTIMUserSign() {
  return request({
    url: '/card-server/IM/withdraw/getUserSign',
    method: 'post',
  });
}
