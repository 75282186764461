<template>
  <component
    :is="this.Icons[iconName]"
    v-if="iconName && this.Icons[iconName]"
  ></component>
</template>

<script>
import * as Icons from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
export default {
  name: "ali-icon",
  data() {
    return {
      Icons,
    };
  },
  props: {
    iconName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
