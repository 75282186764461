import { createApp } from 'vue';
import App from './App.vue';

import store from './store';
import {
  Button,
  message,
  Layout,
  Menu,
  Input,
  Form,
  Card,
  Tabs,
  DatePicker,
  Select,
  Table,
  Pagination,
  Modal,
  Spin,
  Popconfirm,
  Image,
  InputNumber,
  Row,
  Col,
  Upload,
  Cascader,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/common.less';
// import * as Icons from "@ant-design/icons-vue";
import '@/styles/index.less';
import '@/permission.ts';
import router from './router';
const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(Button)
  .use(Layout)
  .use(Menu)
  .use(Input)
  .use(InputNumber)
  .use(Card)
  .use(Tabs)
  .use(DatePicker)
  .use(Select)
  .use(Table)
  .use(Pagination)
  .use(Modal)
  .use(Spin)
  .use(Popconfirm)
  .use(Image)
  .use(Form)
  .use(Row)
  .use(Upload)
  .use(Cascader)
  .use(Col);
app.config.globalProperties.$message = message;
app.mount('#app');
