import { createStore } from 'vuex';
import app from './modules/app';
import user from './modules/user';
import permission from './modules/permission';
import getters from './getter';
import order from './modules/order';

export default createStore({
  state: {},
  getters: getters,
  mutations: {},
  actions: {},
  modules: {
    app,
    user,
    permission,
    order,
  },
});
