import { login, logout, getInfo, getTIMUserSign } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';

const user = {
  state: {
    updateHandleListFlag: false,
    updateHandleOrderListFlag: false,
  },

  mutations: {
    UPDATE_HANDLE_LIST: (state: any, data: any) => {
      state.updateHandleListFlag = data;
    },
    UPDATE_HANDLE_ORDER_LIST: (state: any, data: any) => {
      state.updateHandleOrderListFlag = data;
    },
  },

  actions: {
    //刷新待接单列表
    updateHandleList({ commit }: any, data: any) {
      return new Promise<void>((resolve) => {
        commit('UPDATE_HANDLE_LIST', data);
        resolve();
      });
    },
    //刷新待处理订单列表
    updateHandleOrderList({ commit }: any, data: any) {
      return new Promise<void>((resolve) => {
        commit('UPDATE_HANDLE_ORDER_LIST', data);
        resolve();
      });
    },
  },
};

export default user;
