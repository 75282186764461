import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LayOut from '@/layouts/LayoutIndex.vue';
import process from 'process';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/login/PageIndex.vue'),
  },
  {
    path: '/',
    component: LayOut,
    name: 'page',
    meta: { title: '首页', icon: 'HomeFilled' },
  },
  {
    path: '/adManage',
    component: LayOut,
    redirect: '/adManage/serverManage',
    name: 'adManage',
    meta: { title: '广告列表', icon: 'TrademarkOutlined' },
    children: [
      {
        path: 'adList',
        name: 'adList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/adManage/ServerIndex.vue'
          ),
        meta: { title: '广告列表', icon: 'TrademarkOutlined' },
      },
    ],
  },
  {
    path: '/orderCenter',
    component: LayOut,
    redirect: '/orderCenter/orderManage',
    name: 'orderCenter',
    meta: { title: '订单管理', icon: 'UnorderedListOutlined' },
    children: [
      {
        path: 'orderList',
        name: 'orderList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/orderCenter/OrderIndex.vue'
          ),
        meta: { title: '历史订单列表', icon: 'UnorderedListOutlined' },
      },
      {
        path: 'orderTrade',
        name: 'orderTrade',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/orderTrade/OrderIndex.vue'
          ),
        meta: { title: '订单列表', icon: 'UnorderedListOutlined' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
